import React from "react"
import { graphql } from "gatsby"

import CalYearSeason from "../templates/cal-year-season"

const CalYearPage = ({data, location}) => (
  <CalYearSeason data={data} location={location} />
)
export const query = graphql`
  query {
    allSheetMessiCalYearStats(limit: 15) {
      edges {
        node {
          id
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
    allSheetRonaldoCalYearStats(limit: 15) {
      edges {
        node {
          id
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
  }
`

export default CalYearPage
